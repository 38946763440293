'use client';

import { useTranslations } from 'next-intl';
import { MouseEventHandler } from 'react';

declare let __cmp: any;

export default function CookieConsentLink() {
  const t = useTranslations('main');

  const onCmp: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    __cmp('showScreenAdvanced');
  };

  return <a onClick={onCmp} rel="nofollow">{t('popups.cookies.open')}</a>;
}
