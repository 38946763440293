'use client';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useLocale } from 'next-intl';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import { useBreakpoints } from '@hooks/useBreakpoints';

interface HeaderSearchProps {
  className?: string;
  placeholder: string;
  buttonLabel: string;
}

export default function HeaderSearch({
  className,
  placeholder,
  buttonLabel,
}: HeaderSearchProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isBreakpointUp } = useBreakpoints();
  const lang = useLocale();
  const router = useRouter();

  const submitForm = (query: string) => {
    if (query) {
      router.push(`/${lang}/search?q=${query}`);
      return;
    }

    router.push(`/${lang}/search`);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isBreakpointUp('lg') || isExpanded) {
      submitForm(e.target.q.value);
      return;
    }

    setIsExpanded(true);
  };

  return (
    <form
      action={`/${lang}/search`}
      method="GET"
      className={cn(
        'flex items-center h-full translate-x-9 lg:translate-x-0',
        className,
      )}
      onSubmit={onSubmit}
    >
      <input
        type="text"
        name="q"
        placeholder={placeholder}
        className={cn(
          'bg-white lg:bg-gray-200 text-black px-2 py-[9px] lg:py-[5px] pr-6 rounded-full opacity-100 text-sm placeholder:text-sm placeholder:italic border border-gray-200 focus:outline-none focus:border-gray-500 transition-all',
          { 'hidden opacity-0': !isExpanded && !isBreakpointUp('lg') },
        )}
      />
      <button
        type="submit"
        className={cn(
          'bg-white lg:bg-gray-500 flex items-center justify-center text-premium-dark lg:text-gray-700 rounded-full text-xs w-10 h-10 lg:py-2 lg:px-4 font-bold hover:bg-gray-600 transition-colors -translate-x-9 lg:w-auto lg:h-8',
        )}
      >
        <BiSearch className="w-6 h-6 lg:w-auto lg:h-auto lg:mr-1" />
        <span className="hidden lg:inline">{buttonLabel}</span>
      </button>
    </form>
  );
}
